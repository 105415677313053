



































import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      isDismissed: false,
      interval: 0,
      timeout: 10 * 60 * 1000, // 10 Minutes
    };
  },
  created() {
    this.interval = window.setInterval(this.refreshStatus, this.timeout);
  },
  beforeDestroy() {
    window.clearInterval(this.interval);
  },
  methods: {
    async refreshStatus() {
      this.$store.dispatch('info');
    },
  },
});
